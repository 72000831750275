'use client'

import React, { useEffect, useMemo, useRef } from 'react'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import HeaderA11y from '~/components/layouts/NavbarLayout/Header/HeaderA11y/HeaderA11y'
import HeaderUtility from '~/components/layouts/NavbarLayout/Header/HeaderUtility/HeaderUtility'
import SearchBar from '~/components/search/SearchBar/SearchBar'
import styles from './Header.module.scss'
import { usePathname } from 'next/navigation'
import { CartButton } from '~/components/layouts/NavbarLayout/Header/CartButton'
import { UaLogoButton } from '~/components/layouts/NavbarLayout/Header/UaLogoButton'
import { ProfileButton } from './ProfileButton'
import { HamburgerMenu } from '../Navigation/HamburgerMenu'
import { NavbarProvider } from '../NavbarProvider'
import Navigation from '../Navigation/Navigation'
import MobileNav from '../Navigation/MobileNav/MobileNav'
import { FavoritesButton } from './FavoritesButton'
import type { NavigationTree } from '~/lib/navigation'

export interface HeaderProps {
	minified?: boolean
	navList?: NavigationTree[]
	headerBanner?: JSX.Element | null
}

export function Header({ minified, navList, headerBanner }: HeaderProps) {
	const currentPath = usePathname()
	const { setHeaderElement, isMobile } = useLayoutMeasurement()

	// Set global header height data while we have ref for header
	const headerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		setHeaderElement(headerRef.current)
	}, [setHeaderElement])

	const navBar = useMemo(() => {
		if (isMobile) {
			return (
				<div className={`${styles.navBar} ${styles['mobile-nav-bar']}`} data-testid="nav-bar">
					<HamburgerMenu />

					<div className={styles['nav-utility']}>
						<UaLogoButton isMobile={isMobile} />
						<SearchBar isMobile={isMobile} />
						<div className={styles['nav-utility-right']}>
							<ProfileButton />
							<CartButton minified={minified} />
						</div>
					</div>
				</div>
			)
		}
		return (
			<div className={styles.navBar} data-testid="nav-bar">
				{/* Desktop and Mobile */}
				<UaLogoButton isMobile={isMobile} />
				<div className={styles['nav-utility-left']}>
					{/* Mobile Only */}
					<HamburgerMenu />

					{/* Mobile Only */}
					<ProfileButton />
				</div>
				{isMobile && <SearchBar isMobile={isMobile} />}

				{/* Desktop Only */}
				<Navigation />

				<div className={styles['nav-utility']}>
					{/* Button on Mobile, Edit box on Desktop */}
					{!isMobile && <SearchBar isMobile={isMobile} />}

					{/* Desktop Only */}
					<FavoritesButton />

					<CartButton minified={minified} />
				</div>
			</div>
		)
	}, [isMobile, minified])

	if (minified) {
		return (
			<header className={styles['minified-header']}>
				<div className={styles['minified-header-container']}>
					<UaLogoButton isMobile={isMobile} />
					<CartButton minified={minified} />
				</div>
			</header>
		)
	}

	return (
		<NavbarProvider defaultNavList={navList || []}>
			<header ref={headerRef} className="no-print">
				<div className={styles['header-top']}>
					<HeaderA11y />
					{headerBanner}
					<HeaderUtility currentPath={currentPath} />
				</div>

				<hr className={styles['header-divider']} />

				{navBar}

				{/* Mobile Only */}
				<MobileNav />
			</header>
		</NavbarProvider>
	)
}
