import styles from '~/components/layouts/NavbarLayout/Header/Header.module.scss'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import React from 'react'
import UALogo from '~/components/shared/UALogo'

export const UaLogoButton = ({ isMobile = false }: { isMobile?: boolean }) => {
	return (
		<div className={styles['logo-wrapper']}>
			<LocaleLink href="/" className={styles['logo-link']}>
				<UALogo width={isMobile ? 32 : 42} height={isMobile ? 18 : 24} />
			</LocaleLink>
		</div>
	)
}
