import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from '~/components/search/MobileSearch/MobileSearch.module.scss'
import clsx from 'clsx'
import CloseIcon from '../../primitives/icons/CloseIcon'
import SearchIcon from '~/components/primitives/icons/SearchIcon'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import useNavigation from '~/components/hooks/useNavigation'
import { useDeactivateBodyScroll } from '~/components/hooks/useDeactivateBodyScroll'

interface MobileSearchProps {
	onClearSearch: () => void
	onSearchRedirect: () => void
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	onEnterKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
	searchTerm: string
	onSetSubNavOpen: (string) => void
	children: React.ReactNode
}

const MobileSearch = ({
	onSetSubNavOpen,
	onSearchRedirect,
	onChange,
	searchTerm,
	onClearSearch,
	children,
}: MobileSearchProps) => {
	const formatMessage = useFormatMessage()
	const searchRef = useRef<HTMLDivElement>(null)
	const [placeholderText, setPlaceholderText] = useState(formatMessage('search-placeholder'))
	const [isMobileSearchActive, setIsMobileSearchActive] = useState(false)
	const setIsMobileSearchActiveCallback = useCallback(() => setIsMobileSearchActive(false), [])

	const handlePlaceholderTextChange = () => {
		onSetSubNavOpen('')
		setPlaceholderText(() => (placeholderText ? '' : formatMessage('search-placeholder')))
	}

	const handleFocus = () => {
		handlePlaceholderTextChange()
		setIsMobileSearchActive(true)
	}

	const handleSearch = () => {
		setIsMobileSearchActive(false)
		onSearchRedirect()
	}

	const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key !== 'Enter') return
		const target = event.currentTarget
		target.value = ''
		target.blur()
		handleSearch()
	}

	const handleClearSearch = useCallback(() => {
		const el = document.getElementById('search-input') as HTMLInputElement
		if (el) {
			el.value = ''
		}
		onClearSearch()
	}, [onClearSearch])

	const handleOutsideClick = (e: MouseEvent) => {
		if (searchRef.current && e.target instanceof Node && searchRef.current.contains(e.target)) {
			return
		}
		setIsMobileSearchActive(false)
	}

	useEffect(() => {
		if (isMobileSearchActive) {
			document.addEventListener('click', handleOutsideClick)
		} else {
			document.removeEventListener('click', handleOutsideClick)
		}

		return () => {
			document.removeEventListener('click', handleOutsideClick)
		}
	}, [isMobileSearchActive])

	useEffect(() => {
		// when a user performs certain actions the parent component will update the searchQuery to '' but, html input field
		// will still contain a value so just clear this out in that situation
		if (searchTerm === '') {
			handleClearSearch()
		}
	}, [handleClearSearch, searchTerm])

	// hook disabling background document body scroll when mobile search slideout is active
	useDeactivateBodyScroll(isMobileSearchActive && !!searchTerm)

	const activeSearchIcon = !placeholderText || searchTerm
	useNavigation({
		onRouteChange: () => {
			handleClearSearch()
			setIsMobileSearchActiveCallback()
		},
	})

	return (
		<div className={clsx(styles['mobile-search-wrapper'], isMobileSearchActive && searchTerm && styles.backdrop)}>
			<div
				className={clsx(styles['mobile-search'], (isMobileSearchActive || searchTerm) && styles.active)}
				ref={searchRef}
			>
				<div className={styles['input-wrapper']}>
					<input
						id="search-input"
						data-testid="mobile-search-bar"
						type="text"
						defaultValue={searchTerm || ''}
						placeholder={placeholderText}
						onKeyDown={handleEnterKeyDown}
						onChange={onChange}
						onFocus={handleFocus}
						onBlur={handlePlaceholderTextChange}
						autoComplete={'off'}
						aria-label={formatMessage('search-keyword-or-item-no')}
						aria-controls="search-results"
						aria-haspopup="listbox"
						maxLength={50}
					/>
					<span
						aria-label={formatMessage('clear')}
						name="clear-button"
						role="button"
						onClick={handleClearSearch}
						className={clsx(styles['clear-search-icon'], { [styles.visible]: searchTerm !== '' })}
					>
						<CloseIcon size="XS" />
					</span>
					<span
						aria-label={formatMessage('search')}
						name="search-button"
						role="button"
						onClick={handleSearch}
						className={`${styles['search-icon']} ${activeSearchIcon ? styles.active : ''}`}
					>
						<SearchIcon size="SM" />
					</span>
				</div>
				<div className={clsx(styles.suggestions, isMobileSearchActive && searchTerm && styles.active)}>{children}</div>
			</div>
		</div>
	)
}

export default MobileSearch
