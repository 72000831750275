/* eslint-disable @typescript-eslint/naming-convention */
export const headerLinks = {
	'en-us': {
		needHelp: {
			id: 'need-help',
			url: 'https://help.underarmour.com/s/',
		},
	},
	'en-ca': {
		needHelp: {
			id: 'help-center',
			url: 'https://help.underarmour.ca/s/?language=en_US',
		},
	},
	'fr-ca': {
		needHelp: {
			id: 'need-help',
			url: 'https://help.underarmour.ca/s/?language=fr_CA',
		},
	},
	'es-mx': {
		needHelp: {
			id: 'need-help',
			url: 'https://ayuda.underarmour.com.mx/s/',
		},
	},
	'en-nl': {
		needHelp: {
			id: 'need-help',
			url: 'https://www.underarmour.nl/en-nl/t/faqs.html',
		},
	},
	'en-ie': {
		needHelp: {
			id: 'need-help',
			url: 'https://help.underarmour.ie/s/',
		},
	},
}
