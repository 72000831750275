import styles from './MobileNav.module.scss'
import clsx from 'clsx'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'
import { getCountryCodeByLocale } from '~/lib/i18n/locale'
import useLanguageSelection from '~/components/hooks/useLanguageSelection'
import type { SyntheticEvent } from 'react'
import { IconButton } from '@ua-digital-commerce/ua-web-components/components/IconButton'

interface Props {
	path: string
	locale: string
	onClick?: (e: SyntheticEvent) => void
}
export default function MobileChangeLocationButton({ locale, path, onClick }: Readonly<Props>) {
	const formatMessage = useFormatMessage()
	const { selectableLanguages, getRouteByLanguage } = useLanguageSelection(locale, path)
	return (
		<>
			<li className={clsx(styles['mobile-menu-item'], styles['location-change-item'])}>
				<LocaleLink
					href="/change-location/"
					role="mobile-menu-item"
					aria-label={formatMessage('change-site-location')}
					onClick={onClick}
				>
					<i className={`${styles['region-icon']} fflag fflag-${getCountryCodeByLocale(locale).toUpperCase()}`} />
					<span>{`${formatMessage('region')}: ${getCountryCodeByLocale(locale).toUpperCase()}`}</span>
					<IconButton className={styles['mobile-forward-arrow']} label={formatMessage('change-site-location')}>
						<ArrowIcon size="MD" />
					</IconButton>
				</LocaleLink>
			</li>
			<ul className={clsx(styles['mobile-sub-menu'], styles['location-change-item'])}>
				{selectableLanguages.map((language) => (
					<li key={language} className={styles['mobile-menu-item']}>
						<a href={getRouteByLanguage(language)}>
							<span>{formatMessage(`language-${language}`)}</span>
						</a>
					</li>
				))}
			</ul>
		</>
	)
}
